const onRouteUpdate = function({location}) {
  if (process.env.NODE_ENV === `production` && typeof fbq === `function`) {
    if (typeof window !== 'undefined') {
      if (window.fbq != null) {
        window.fbq('track', 'ViewContent')
      }
    }
  }
}

export {onRouteUpdate}
